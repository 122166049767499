import 'bootstrap'

import './scss/app.scss'

// Import custom scripts
import './js/scripts';

// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}